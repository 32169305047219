<template>
  <div class="overflow-hidden">
    <div class="relative z-[9999]">
      <Container>
        <div class="navbar py-4">
          <div class="navbar-start">
            <NuxtLink to="/" no-prefetch>
              <NuxtImg
                class="h-10"
                src="/surya-bintang-logo-dark.png"
                alt="logo"
                format="webp"
                height="40"
                width="88"
                fetchPriority="high"
              />
            </NuxtLink>
          </div>

          <div class="navbar-end">
            <div class="hidden lg:block">
              <div class="flex justify-center space-x-6">
                <NuxtLink
                  class="btn btn-ghost transition-all duration-300 normal-case rounded-none btn-sm text-base font-normal h-[38px] text-white hover:text-black hover:bg-white hover:rounded-lg"
                  to="/"
                  active-class="!font-semibold"
                  no-prefetch
                >
                  {{ $t('nav.home') }}
                </NuxtLink>
                <NuxtLink
                  class="btn btn-ghost transition-all duration-300 normal-case rounded-none btn-sm text-base font-normal h-[38px] text-white hover:text-black hover:bg-white hover:rounded-lg"
                  to="/about"
                  active-class="!font-semibold"
                  no-prefetch
                >
                  {{ $t('nav.about') }}
                </NuxtLink>

                <NuxtLink
                  class="btn btn-ghost transition-all duration-300 normal-case rounded-none btn-sm text-base font-normal h-[38px] text-white hover:text-black hover:bg-white hover:rounded-lg"
                  to="/activities"
                  active-class="!font-semibold"
                  no-prefetch
                >
                  {{ $t('nav.activities') }}
                </NuxtLink>

                <NuxtLink
                  class="btn btn-ghost transition-all duration-300 normal-case rounded-none btn-sm text-base font-normal h-[38px] text-white hover:text-black hover:bg-white hover:rounded-lg"
                  to="/contact-us"
                  active-class="!font-semibold"
                  no-prefetch
                >
                  {{ $t('nav.contact') }}
                </NuxtLink>
                <!-- <ClientOnly>
                  <NuxtLink
                    class="indicator btn btn-ghost transition-all duration-300 normal-case rounded-none btn-sm text-base font-normal h-[38px] text-white hover:text-black hover:bg-white hover:rounded-lg"
                    to="/cart"
                    v-if="isAgentOrUser"
                    active-class="!font-semibold"
                    no-prefetch
                  >
                    {{ $t('nav.cart') }}
                    <span v-if="availableCart" class="indicator-item badge badge-success badge-xs"></span>
                  </NuxtLink>
                </ClientOnly> -->

                <NuxtLink
                  v-if="$isLogin"
                  class="btn btn-sm text-base font-normal h-[38px] rounded-none normal-case bg-black text-white px-5 transition-all duration-300 outline-none border-none hover:bg-white hover:rounded-lg hover:text-black"
                  :to="dashboardLink"
                  no-prefetch
                >
                  Dashboard
                </NuxtLink>
                <NuxtLink
                  v-else
                  class="btn btn-sm text-base font-normal h-[38px] rounded-none normal-case bg-black text-white px-5 transition-all duration-300 outline-none border-none hover:bg-white hover:text-black hover:rounded-lg"
                  to="/sign-up"
                  no-prefetch
                >
                  Sign Up
                </NuxtLink>
                <div>
                  <!-- <LanguageDropdown is-dark /> -->
                </div>
              </div>
            </div>
            <div class="block lg:hidden">
              <div class="flex items-center space-x-2">
                <div>
                  <!-- <LanguageDropdown is-dark /> -->
                </div>
                <VMenu placement="auto" instant-move :triggers="['hover', 'click']">
                  <button
                    class="btn btn-square btn-sm text-base font-normal normal-case"
                    type="button"
                    aria-label="Menu"
                  >
                    <Icon name="i-heroicons-bars-3" class="h-6 w-6" />
                  </button>
                  <template #popper>
                    <div class="dropdown-content z-[1] p-2 shadow bg-base-100 rounded-lg w-64 space-y-1.5">
                      <ul class="space-y-1 menu">
                        <li>
                          <NuxtLink to="/" active-class="active" no-prefetch>
                            {{ $t('nav.home') }}
                          </NuxtLink>
                        </li>
                        <li>
                          <NuxtLink to="/about" active-class="active" no-prefetch>
                            {{ $t('nav.about') }}
                          </NuxtLink>
                        </li>
                        <li>
                          <NuxtLink to="/activities" active-class="active" no-prefetch>
                            {{ $t('nav.activities') }}
                          </NuxtLink>
                        </li>
                        <li v-if="isAgentOrUser">
                          <ClientOnly>
                            <NuxtLink to="/cart" active-class="active" no-prefetch class="indicator">
                              {{ $t('nav.cart') }}
                              <span v-if="availableCart" class="indicator-item badge badge-success badge-xs"></span>
                            </NuxtLink>
                          </ClientOnly>
                        </li>
                        <li>
                          <NuxtLink to="/contact-us" active-class="active" no-prefetch>
                            {{ $t('nav.contact') }}
                          </NuxtLink>
                        </li>
                      </ul>
                      <hr />

                      <ul class="menu">
                        <li v-if="$isLogin">
                          <NuxtLink
                            :to="dashboardLink"
                            class="btn btn-neutral rounded-full normal-case btn-sm text-base font-normal h-10 btn-block"
                            aria-label="menu"
                          >
                            Dashboard
                          </NuxtLink>
                        </li>
                        <li v-else>
                          <NuxtLink
                            to="/sign-up"
                            class="btn rounded-full normal-case btn-sm text-base font-normal btn-block"
                            aria-label="menu"
                          >
                            Sign Up
                          </NuxtLink>
                        </li>
                      </ul>
                    </div>
                  </template>
                </VMenu>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <main class="min-h-screen">
      <slot />
    </main>
    <FloatingButton />
    <DarkFooter />
    <NuxtSnackbar />
  </div>
</template>

<script setup lang="ts">
const { $isLogin, $isAgen, $isAdmin, $isUser } = useAuth()
const store = useProductStore()

const dashboardLink = computed(() => {
  if ($isAdmin.value) {
    return '/admin'
  }
  if ($isAgen.value) {
    return '/agent'
  }
  return '/user'
})

const availableCart = computed(() => {
  return store.availableCart
})

const isAgentOrUser = computed(() => {
  return $isUser.value || $isAgen.value
})
</script>

<style scoped></style>
